import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/vercel/path0/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`Skill Practice: D Ball Cleans & Double Unders`}</p>
    <p><em parentName="p">{`Death by: `}</em></p>
    <p>{`Minute 1:`}</p>
    <p>{`1-D Ball Clean Over Shoulders (80/40)`}</p>
    <p>{`10-Double Unders`}</p>
    <p>{`Minute 2:`}</p>
    <p>{`2-D Ball Cleans`}</p>
    <p>{`10-Double Unders`}</p>
    <p>{`etc,`}</p>
    <p>{`2:00 after finishing “death by” start 25:00 AMRAP of:`}</p>
    <p>{`200M Run`}</p>
    <p>{`10-CTB Pullups`}</p>
    <p>{`15-KBS (53/35)`}</p>
    <p>{`20-Air Squats`}</p>
    <p><strong parentName="p"><em parentName="strong">{`*`}{`Get your team registered for the Granite Games Fall Throwdown here
at The Ville September 14th!  Only 7 spots left!  Learn more about the
Throwdown here:
 `}<a parentName="em" {...{
            "href": "https://thegranitegames.com/about-throwdowns/"
          }}>{`https://thegranitegames.com/about-throwdowns/   `}</a></em></strong></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      